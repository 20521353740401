import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button, Form, Table } from 'react-bootstrap'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../actions'

function Searcher(props) {
    const [data, setData] = useState([])
    const [busqueda, setBusqueda] = useState('')

    const transformarFecha = (date) => {
        const dia = date.split("-")[0]
        const mes = date.split("-")[1] - 1
        let anio = date.split("-")[2]
        if (anio < 2000) anio = "" + 20 + anio
        return new Date(anio, mes, dia)
    }

    function compare(a, b) {
        if (transformarFecha(a) > transformarFecha(b)) {
            return -1;
        }
        if (transformarFecha(a) < transformarFecha(b)) {
            return 1;
        }
        return 0;
    }

    const handleChange = (e, ix) => {
        if (e.target.name === 'tienePromo') {
            setData(prev => ([...prev, prev[ix][e.target.name] = e.target.checked]))
        } else {
            setData(prev => ([...prev, prev[ix][e.target.name] = e.target.value]))
        }
    }
    console.log(data)

    const handleUpdate = (id) => {
        axios.put('/updateClient', data.find(d => d.id === id))
    }

    useEffect(() => {
        axios.get(`/allClients`)
            .then(r => {
                let res = r.data.sort((a, b) => compare(a.dia, b.dia))
                setData(res)
            })
    }, [])

    const searching = (e) => {
        setBusqueda(e)
    }

    return (
        <div className='promotion mt-1'>
            <div className="botones mb-4">
                <Form.Control
                    value={busqueda}
                    onChange={(e) => searching(e.target.value)}
                    type="text"
                    placeholder="Buscar..."
                />
                <Button variant="primary" onClick={() => props.history.push("/admin")}>
                    Volver
                </Button>
                <hr />
            </div>
            {
                busqueda.length >= 3 ?
                    data.map((item, ix) => {
                        let nombre = item.nombre ? item.nombre.toLowerCase() : ''
                        if (nombre.includes(busqueda.toLowerCase())) {
                            return (
                                < div className='card m-2 p-2'>
                                    <div key={item.id} className="card-body">
                                        <h5 className='card-title'>
                                            DNI: {item.idCliente}
                                        </h5>
                                        <div>
                                            <label>Nombre</label>
                                            <input
                                                value={item.nombre}
                                                name={'nombre'}
                                                onChange={(e) => handleChange(e, ix)}
                                            />
                                        </div>
                                        <div>
                                            <label>Dia Promo</label>
                                            <input
                                                value={item.diaPromo}
                                                name={'diaPromo'}
                                                onChange={(e) => handleChange(e, ix)}
                                                size={8}
                                            />
                                        </div>
                                        <div>
                                            <label>Dia Turno</label>
                                            <input
                                                value={item.dia}
                                                name={'dia'}
                                                onChange={(e) => handleChange(e, ix)}
                                                size={8}
                                            />
                                        </div>
                                        <div>
                                            <label>Turno</label>
                                            <input
                                                value={item.turno}
                                                name={'turno'}
                                                onChange={(e) => handleChange(e, ix)}
                                                maxLength={5}
                                                size={5}
                                            />
                                        </div>

                                    </div>

                                    <div>
                                        <label>Tiene Promo</label>
                                        <input
                                            type={'checkbox'}
                                            checked={item.tienePromo}
                                            name={'tienePromo'}
                                            onChange={(e) => handleChange(e, ix)}
                                        />
                                    </div>
                                    <div>
                                        <Button onClick={() => handleUpdate(item.id)}>
                                            Actualizar
                                        </Button>
                                        <Button onClick={() => props.contactMe(item.telefono)} className={'mx-1'}>
                                            Wsp
                                        </Button>
                                    </div>
                                </div>
                            )
                        }
                    })
                    :
                    <tr></tr>
            }
        </div>
    )
}

const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(actionCreators, dispatch)
}

export default connect(null, mapDispatchToProps)(Searcher);
